import * as tslib_1 from "tslib";
import { Vue, Component, PropSync } from 'vue-property-decorator';
import { LANGUAGE_LIST } from '@/common/js/constant/index.ts';
let DrawerlangSelector = class DrawerlangSelector extends Vue {
    constructor() {
        super(...arguments);
        this.LANGUAGE_LIST = LANGUAGE_LIST;
    }
    // 切换语言
    changeLang(lang) {
        this.isShowDrawer = false;
        if (lang === KLOOK.getLocale()) {
            return;
        }
        const url = (lang === 'en' ? '' : `/${lang}`) + this.$route.path;
        window.location.replace(url);
    }
};
tslib_1.__decorate([
    PropSync('show', { type: Boolean })
], DrawerlangSelector.prototype, "isShowDrawer", void 0);
DrawerlangSelector = tslib_1.__decorate([
    Component
], DrawerlangSelector);
export default DrawerlangSelector;
