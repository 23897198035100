import { render, staticRenderFns } from "./drawer-manage-account.vue?vue&type=template&id=7f9979fa&scoped=true&"
import script from "./drawer-manage-account.vue?vue&type=script&lang=ts&"
export * from "./drawer-manage-account.vue?vue&type=script&lang=ts&"
import style0 from "./drawer-manage-account.vue?vue&type=style&index=0&id=7f9979fa&lang=less&scoped=true&"
import style1 from "./drawer-manage-account.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f9979fa",
  null
  
)

export default component.exports