import * as tslib_1 from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import Cookie from 'js-cookie';
import { namespace } from 'vuex-class';
import { URLS } from '@/common/js/constant/index';
import DrawerManageAccount from './drawer-manage-account.vue';
import DrawerSetPassword from './drawer-set-password.vue';
import DrawerLangSelector from './drawer-lang-selector.vue';
import Foot from '@/views/_modules/mobile/footer.vue';
let User = class User extends Vue {
    constructor() {
        super(...arguments);
        this.isLogoutLoading = false;
        this.isShowDrawer = {
            manageAccount: false,
            setPassword: false,
            langSelector: false,
        };
    }
    logout() {
        this.isLogoutLoading = true;
        this.$ajax.get(URLS.logout).then(() => {
            this.$store.commit('user/cleanUserInfo');
            Cookie.remove('isLogin');
            this.$router.push({ name: 'login' });
        });
    }
};
tslib_1.__decorate([
    namespace('user').State('username')
], User.prototype, "userName", void 0);
tslib_1.__decorate([
    namespace('user').State('user_type')
], User.prototype, "userType", void 0);
User = tslib_1.__decorate([
    Component({
        components: {
            DrawerManageAccount,
            DrawerSetPassword,
            DrawerLangSelector,
            Foot,
        },
    })
], User);
export default User;
