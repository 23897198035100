import * as tslib_1 from "tslib";
import { Vue, Component, Watch, PropSync } from 'vue-property-decorator';
import { URLS } from '@/common/js/constant/index';
import Back from './common-back.vue';
import DrawerSetSubAccountPwd from './drawer-set-sub-account-pwd.vue';
import DrawerAddAccount from './drawer-add-account.vue';
let DrawerManageAccount = class DrawerManageAccount extends Vue {
    constructor() {
        super(...arguments);
        this.operateInfo = {};
        this.list = [];
        this.isListLoading = false;
        this.isShowSetPwdDrawer = false;
        this.isShowAddDrawer = false;
        this.isListEnd = false;
        this.pagination = {
            total: 0,
            current: 1,
            defaultPageSize: 10,
        };
    }
    processListEnd() {
        const { total, current, defaultPageSize } = this.pagination;
        this.isListEnd = current * defaultPageSize >= total;
    }
    onShowChanged(val) {
        if (val) {
            this.getUserList();
        }
        else {
            this.initList();
        }
    }
    // 获取账号列表
    getUserList() {
        if (this.isListEnd || this.isListLoading) {
            return;
        }
        this.isListLoading = true;
        this.$ajax.get(URLS.getUserList, {
            page: this.pagination.current,
            limit: this.pagination.defaultPageSize,
        }).then((res) => {
            this.list = this.list.concat(res.user_list || []);
            this.pagination.total = res.total;
            this.isListLoading = false;
            this.processListEnd();
            this.pagination.current++;
        });
    }
    initList() {
        this.list = [];
        this.pagination.current = 1;
        this.isListEnd = false;
    }
    /**
     * 锁定账号
     * @param {Object} info 用户信息
     */
    lock(info) {
        this.$confirm({
            class: 'modal-no-icon-title',
            content: this.renderTips('manage_account.lock_tips', info.username),
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.$ajax.post(URLS.lockUser, {
                        user_guid: info.user_guid,
                    }).then((res) => {
                        resolve();
                        this.$successTips();
                        this.initList();
                        this.getUserList();
                    }).catch(() => {
                        reject();
                    });
                });
            },
        });
    }
    /**
     * 解锁账号
     * @param {Object} info 用户信息
     */
    unlock(info) {
        this.$confirm({
            class: 'modal-no-icon-title',
            content: this.renderTips('manage_account.unlock_tips', info.username),
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.$ajax.post(URLS.unlockUser, {
                        user_guid: info.user_guid,
                    }).then((res) => {
                        resolve();
                        this.$successTips();
                        this.initList();
                        this.getUserList();
                    }).catch(() => {
                        reject();
                    });
                });
            },
        });
    }
    /**
     * 重发激活信息
     * @param {Object} info 用户信息
     */
    resendMessage(info) {
        this.$confirm({
            class: 'modal-no-icon-title',
            content: this.renderTips('manage_account.resend_message_tips', info.username),
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.$ajax.post(URLS.resendActiveMessage, {
                        user_guid: info.user_guid,
                    }).then((res) => {
                        resolve();
                        this.$successTips();
                    }).catch(() => {
                        reject();
                    });
                });
            },
        });
    }
    /**
     * 删除账号
     * @param {Object} info 用户信息
     */
    deleteUser(info) {
        const h = this.$createElement;
        this.$confirm({
            class: 'modal-no-icon-title',
            content: this.renderTips('manage_account.delete_tips', info.username),
            okType: 'danger',
            okText: this.$t('global.delete'),
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.$ajax.post(URLS.deleteUser, {
                        user_guid: info.user_guid,
                    }).then((res) => {
                        resolve();
                        this.$successTips();
                        this.initList();
                        this.getUserList();
                    }).catch(() => {
                        reject();
                    });
                });
            },
        });
    }
    /**
     * 重置密码
     * @param {Object} info 用户信息
     */
    reset(info) {
        this.operateInfo = info;
        this.isShowSetPwdDrawer = true;
    }
    /**
     * 渲染提示
     * @param {string} key 提示多语言key
     * @param {srting} username 用户名
     */
    renderTips(key, name) {
        const h = this.$createElement;
        return h('span', {
            domProps: {
                innerHTML: this.$t(key, {
                    name: '<span class="operate-tips-name">' + name + '</span>',
                }),
            },
        });
    }
};
tslib_1.__decorate([
    PropSync('show', { type: Boolean })
], DrawerManageAccount.prototype, "isShowDrawer", void 0);
tslib_1.__decorate([
    Watch('show')
], DrawerManageAccount.prototype, "onShowChanged", null);
DrawerManageAccount = tslib_1.__decorate([
    Component({
        components: {
            DrawerSetSubAccountPwd,
            DrawerAddAccount,
            Back,
        },
        directives: {
            scrollLoad: {
                bind(elem, binding) {
                    let timeout = '';
                    elem.addEventListener('scroll', (e) => {
                        timeout = setTimeout(() => {
                            if (elem.scrollHeight <= elem.clientHeight + elem.scrollTop + 10) {
                                binding.value();
                                clearTimeout(timeout);
                            }
                        }, 300);
                    });
                },
            },
        },
    })
], DrawerManageAccount);
export default DrawerManageAccount;
